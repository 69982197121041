import Counter from './Counter'

export default function App() {

    return(
        <>
            <Counter />
        </>
    )

}